import { RooDialog } from '../../RooDialog';
import { Alert, Box, Button, CircularProgress, IconButton, Stack, styled, Typography } from '@mui/material';
import { MuiIcon } from 'shared/icons';
import { Center } from 'components/Center';
import React, { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { compressImage } from './utils';
import { useFileArea } from './FilePicker.store';
import { getCanvasHeight, getCanvasWidth, getPositions, getRowLayout } from './collage/placement';
import { makeUuid, useListState, UseListStateHandlers } from '@roo/lib';

type CollageFile = {
  id: string;
  rawFile: File;

  calculatingSize: boolean;
  width?: number;
  height?: number;

  thumbnailUrl?: string;
  generatingThumbnail: boolean;
};

const MAX_CANVAS_PIXELS = 4096 * 4096;

export const ViewCollage = () => {
  const { requestView, addFiles } = useFileArea((x) => x.actions);
  const [files, handlers] = useListState<CollageFile>([]);
  const [state, setState] = useState<{ status: 'running' | 'idle' | 'error'; resultFile?: Blob; resultUrl?: string }>({
    status: 'idle'
  });
  const calculatingSize = files.some((x) => x.calculatingSize);

  const presenceKey = files.map((x) => x.id).join('|');
  const generationKey = files.map((x) => `${x.id}|${x.calculatingSize}`).join('|');

  useEffect(() => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.thumbnailUrl != null || file.generatingThumbnail) {
        continue;
      }

      void generateThumbnail(file, i, handlers);
    }
  }, [presenceKey]);

  useEffect(() => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.thumbnailUrl != null || file.generatingThumbnail) {
        continue;
      }

      void generateThumbnail(file, i, handlers);
    }
  }, [presenceKey]);

  useEffect(() => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.thumbnailUrl != null || file.generatingThumbnail) {
        continue;
      }

      void fillImageSize(file, i, handlers);
    }
  }, [presenceKey]);

  useEffect(() => {
    if (state.resultUrl != null) {
      URL.revokeObjectURL(state.resultUrl);
      setState((x) => ({ ...x, resultUrl: null }));
    }

    if (files.length < 2 || files.some((x) => x.height == null || x.width == null)) {
      return;
    }

    const generateBlob = async () => {
      const rows = getRowLayout(
        files.map((x) => ({ uri: x.id, width: x.width, height: x.height })),
        1920,
        1920
      );

      const originalHeight = getCanvasHeight(rows);
      const originalWidth = getCanvasWidth(rows);

      const originalPixels = originalWidth * originalHeight;
      const scalingRatio = originalPixels > MAX_CANVAS_PIXELS ? Math.sqrt(MAX_CANVAS_PIXELS / originalPixels) : 1;

      const canvasWidth = Math.floor(originalWidth * scalingRatio);
      const canvasHeight = Math.floor(originalHeight * scalingRatio);

      const originalPositions = getPositions(rows);
      const scaledPositions = originalPositions.map((row) =>
        row.map((pos) => ({
          x: Math.floor(pos.x * scalingRatio),
          y: Math.floor(pos.y * scalingRatio)
        }))
      );

      const canvas = document.createElement('canvas');
      canvas.width = canvasWidth;
      canvas.height = canvasHeight;
      const ctx = canvas.getContext('2d')!;

      async function drawImage(blob: Blob, x: number, y: number, width: number, height: number): Promise<void> {
        return new Promise((resolve, reject) => {
          const img: HTMLImageElement = new Image();
          img.onload = () => {
            const scaledWidth = Math.floor(width * scalingRatio);
            const scaledHeight = Math.floor(height * scalingRatio);

            ctx.drawImage(img, x, y, scaledWidth, scaledHeight);
            resolve();
          };
          img.onerror = (e) => reject(e);
          img.src = URL.createObjectURL(blob);
        });
      }

      for (let i = 0; i < rows.length; i++) {
        const pictures = rows[i];
        for (let j = 0; j < pictures.length; j++) {
          const picture = pictures[j];
          const match = files.find((x) => x.id === picture.uri);
          const position = scaledPositions[i][j];
          await drawImage(match.rawFile, position.x, position.y, picture.width, picture.height);
        }
      }

      function getCanvasBlob(mimeType: string = 'image/jpeg', qualityArgument: number = 1.0): Promise<Blob> {
        return new Promise((resolve, reject) => {
          if (!canvas.toBlob) {
            return reject(new Error('Canvas toBlob method is not supported'));
          }
          canvas.toBlob(
            (blob: Blob | null) => {
              blob ? resolve(blob) : reject(new Error('Canvas to Blob conversion failed'));
            },
            mimeType,
            qualityArgument
          );
        });
      }

      return await getCanvasBlob();
    };

    const run = async () => {
      setState({ status: 'running', resultFile: null });
      try {
        const blob = await generateBlob();
        setState({ status: 'idle', resultFile: blob, resultUrl: URL.createObjectURL(blob) });
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
        setState({ status: 'error', resultFile: null });
      }
    };

    void run();
  }, [generationKey, calculatingSize]);

  const filesRef = useRef(files);
  filesRef.current = files;
  const stateRef = useRef(state);
  stateRef.current = state;
  useEffect(() => {
    return () => {
      if (stateRef.current.resultUrl != null) {
        URL.revokeObjectURL(stateRef.current.resultUrl);
      }
      filesRef.current.forEach((x) => URL.revokeObjectURL(x.thumbnailUrl));
    };
  }, []);

  return (
    <>
      <RooDialog.Content sx={{ minHeight: 350, display: 'flex', flexDirection: 'column' }}>
        <Stack sx={{ flexGrow: 1 }}>
          {files.length < 2 && (
            <Center sx={{ flexGrow: 1 }}>
              <Typography variant={'subtitle1'} align={'center'}>
                Add at least 2 images to create a collage
              </Typography>
            </Center>
          )}
          {state.status === 'running' && (
            <Center sx={{ flexGrow: 1 }}>
              <CircularProgress size={48} />
            </Center>
          )}
          {state.status === 'idle' && state.resultUrl != null && (
            <Center>
              <img alt={''} style={{ maxHeight: '100%', maxWidth: '100%' }} src={state.resultUrl} />
            </Center>
          )}
          {state.status === 'error' && (
            <Center>
              <Alert severity={'error'}>Failed to generate collage</Alert>
            </Center>
          )}
        </Stack>
        {files.length > 0 && (
          <Stack spacing={1} direction={'row'} sx={{ overflowX: 'auto', overflowY: 'hidden', pt: 2 }}>
            {files.map((x, i) => (
              <Box key={i} sx={{ position: 'relative', overflow: 'visible' }}>
                {x.thumbnailUrl == null && (
                  <Center sx={{ width: 64, height: 64 }}>
                    <CircularProgress size={48} />
                  </Center>
                )}
                {x.thumbnailUrl != null && <img height={64} width={64} src={x.thumbnailUrl} alt={x.rawFile.name} />}
                <IconButton
                  sx={{ position: 'absolute', top: -14, right: -14 }}
                  onClick={() => {
                    handlers.remove(i);
                    if (x.thumbnailUrl != null) {
                      URL.revokeObjectURL(x.thumbnailUrl);
                    }
                  }}
                  size={'small'}
                  color={'error'}
                >
                  <MuiIcon.Delete />
                </IconButton>
              </Box>
            ))}
          </Stack>
        )}
      </RooDialog.Content>
      <RooDialog.Actions>
        <Center sx={{ width: '100%' }}>
          <Stack spacing={1} sx={{ maxWidth: 400 }}>
            <Stack spacing={1} direction={'row'}>
              <AddMoreButton handlers={handlers} />
              <Button
                disabled={state.resultFile == null}
                onClick={() => {
                  const id = makeUuid();
                  addFiles([
                    {
                      assetId: id,
                      rawFile: state.resultFile,
                      fileName: `collage-${id}.jpg`,
                      state: 'pending'
                    }
                  ]);
                }}
                sx={{ minWidth: 140 }}
                startIcon={<MuiIcon.Save />}
              >
                Save
              </Button>
            </Stack>
            <Button
              onClick={() => requestView('source-selector')}
              color={'secondary'}
              fullWidth
              startIcon={<MuiIcon.Cancel />}
            >
              Cancel
            </Button>
          </Stack>
        </Center>
      </RooDialog.Actions>
    </>
  );
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const AddMoreButton = ({ handlers }: { handlers: UseListStateHandlers<CollageFile> }) => {
  return (
    <Button fullWidth component="label" variant={'contained'} startIcon={<MuiIcon.Add />}>
      Add Image
      <VisuallyHiddenInput
        type="file"
        accept={'image/*'}
        multiple={true}
        onChange={(ev) => {
          const files = ev.target.files;
          if (files == null) {
            return;
          }

          handlers.append(
            ...Array.from(files).map((x) => ({
              id: makeUuid(),
              rawFile: x,
              generatingThumbnail: false,
              calculatingSize: false
            }))
          );
          ev.target.value = null;
        }}
      />
    </Button>
  );
};

const generateThumbnail = async (file: CollageFile, idx: number, handlers: UseListStateHandlers<CollageFile>) => {
  handlers.setItemProp(idx, 'generatingThumbnail', true);
  try {
    const thumbnail = await compressImage(file.rawFile, 0.8, 256, 256);
    const url = URL.createObjectURL(thumbnail);
    handlers.setItemProp(idx, 'thumbnailUrl', url);
  } catch (e) {
    Sentry.captureException(e);
  }

  handlers.setItemProp(idx, 'generatingThumbnail', false);
};

const fillImageSize = async (file: CollageFile, idx: number, handlers: UseListStateHandlers<CollageFile>) => {
  handlers.setItemProp(idx, 'calculatingSize', true);
  try {
    const loaded = await getImageSize(file.rawFile);
    handlers.setItemProp(idx, 'width', loaded.width);
    handlers.setItemProp(idx, 'height', loaded.height);
  } catch (e) {
    Sentry.captureException(e);
  }
  handlers.setItemProp(idx, 'calculatingSize', false);
};

const getImageSize = async (file: File) => {
  const originalUrl = URL.createObjectURL(file);
  const onLoad = new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.src = originalUrl;
    image.addEventListener('load', () => {
      URL.revokeObjectURL(originalUrl);
      resolve(image);
    });
    image.addEventListener('error', (event) => {
      URL.revokeObjectURL(originalUrl);
      reject(event.error || new Error('Could not create thumbnail'));
    });
  });

  const loaded = await onLoad;
  return { width: loaded.naturalWidth, height: loaded.naturalHeight };
};
