import { Grid, Typography } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { RooDialog, RouterLink } from 'components';
import { CenteredLoader } from 'components/CenteredLoader';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect } from 'react';
import { apiProvider } from 'shared/api/apiProvider';
import { FilesExpirationDate } from 'shared/api/clients';
import { Routes } from 'shared/routing';
import { rooFmt } from 'shared/utils';

export const WarningsListModal = ({
  vendorId,
  open,
  onClose
}: {
  vendorId: string;
  open: boolean;
  onClose: () => void;
}) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (open) {
      queryClient.invalidateQueries(['filesExpirationData', vendorId]);
    }
  }, [open, queryClient, vendorId]);

  const { isLoading, data } = useQuery(
    ['filesExpirationData', vendorId],
    () => apiProvider.vendorClient.getExpirationFiles(vendorId),
    {
      enabled: open
    }
  );

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <RooDialog open={open} onClose={onCloseHandler}>
      <RooDialog.Title onClose={onCloseHandler}>Warnings</RooDialog.Title>
      <RooDialog.Content>
        {isLoading && <CenteredLoader />}

        {!isLoading && data.length === 0 && (
          <Typography sx={{ p: 4 }} align={'center'} variant={'h6'}>
            No files available
          </Typography>
        )}
        {!isLoading && data.length > 0 && <GalleryEntries files={data} isLoading={isLoading} />}
      </RooDialog.Content>
    </RooDialog>
  );
};

const GalleryEntries = ({ files, isLoading }: { files: FilesExpirationDate[]; isLoading: boolean }) => {
  return (
    <Grid container spacing={2} justifyContent={{ xs: 'center', md: 'flex-start' }}>
      <DataTable paginator loading={isLoading} rows={10} value={files} dataKey={'id'}>
        <Column
          header={'File Name'}
          field={'fileName'}
          sortable
          body={(data: FilesExpirationDate) => {
            return (
              <Typography gutterBottom sx={{ fontWeight: 'bold' }}>
                <RouterLink to={{ pathname: Routes.VendorProfile, params: { vendorId: data.vendorId } }}>
                  {data.fileName}
                </RouterLink>
              </Typography>
            );
          }}
        />
        <Column
          sortable
          filter
          showFilterMatchModes={false}
          showFilterOperator={false}
          showAddButton={false}
          filterMatchMode={'contains'}
          field={'documentTypeName'}
          header={'Document Type'}
        />
        <Column
          field={'expirationDate'}
          sortable
          header={'Expiration Date'}
          dataType={'date'}
          body={(row: FilesExpirationDate) => rooFmt.date(row.expirationDate)}
        />
      </DataTable>
    </Grid>
  );
};
