import { ReactNode } from 'react';
import { FieldError } from 'react-hook-form';

export const ErrorMessage = ({ error }: { error: FieldError }) => {
  if (error == null) {
    return null;
  }

  return <div className={'invalid-feedback'}>{error.message}</div>;
};

export const getMuiHelperText = (helperText: ReactNode, error: FieldError) => {
  if (error?.message == null) {
    return helperText;
  }

  return error.message;
};
