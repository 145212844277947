import { History, Location } from 'history';
import { createBrowserHistory } from 'history';
import { generatePath, matchPath } from 'react-router';
import { useModalStore } from 'components/modals/modalStore';

export const browserHistory = createBrowserHistory({
  basename: '/'
});

export const LegalLinks = {
  Accessibility: '/legal/accessibility',
  Cookies: '/legal/cookies',
  Privacy: '/legal/privacy',
  Security: '/legal/security',
  TermsAndConditions: '/legal/terms-and-conditions',
  TermsOfUse: '/legal/terms-of-use'
} as const;

export const Routes = {
  ...{
    Register: '/register',
    LogIn: '/login',
    LogInMagicLink: '/login-magic-link',
    Landing: '/landing',
    TwoFactor: '/2fa',
    ResetPassword: '/reset-password',
    RequestPasswordReset: '/request-password-reset',
    PayInvoice: '/pay-invoice/:id/:userId'
  },
  ...{
    CurrentUserProfile: '/user/profile',
    UserProfile: '/user/profile/:id?/:tab?',
    NewUserProfile: '/user/profile/new/:id',
    ConfirmEmail: '/user/email-confirmation'
  },
  ...{
    PropertyList: '/properties',
    PropertyCreate: '/properties/create',
    PropertyView: '/properties/:id',
    PropertyEdit: '/properties/:id/edit'
  },
  ...{
    VendorCreate: '/users/vendors/create',
    VendorList: '/users/vendors',
    ManagerList: '/users/managers',
    TenantList: '/users/tenants',
    OwnerList: '/users/owners',
    VendorView: '/vendor/:id',
    DocumentTypeList: '/document-type'
  },
  ...{
    WorkorderList: '/workorders/list',
    WorkorderCreate: '/workorders/create',
    WorkorderView: '/workorders/:id',
    IssueView: '/workorders/:workorderId/issue/:issueId',
    IssueBidCompare: '/workorders/:workorderId/issue/:issueId/compare-bids',
    IssueBidCompareReadonly: '/workorders/:workorderId/issue/:issueId/compare-bids-readonly',
    IssueCreate: '/issues/create',
    CommissionsReport: '/commissions/report'
  },
  ...{
    Dashboard: '/',
    DashboardAlt: '/dashboard',
    AboutUsLegal: '/about-us/legal',
    AccountsReceivable: '/reports/accounts-receivable',
    AccountsPayable: '/reports/accounts-payable',
    Payees: '/reports/payees',
    ProfitLoss: '/reports/profit-loss',
    VendorClients: '/my-clients/',
    CompanyProfile: '/company/:companyId/:tab?',
    VendorProfile: '/vendor/:vendorId/:tab?',
    InspectionsDashboard: '/inspections/dashboard',
    InspectionReport: '/inspections/:id/report',
    InspectionView: '/inspections/:id'
  },
  ...{
    AdminAccountsList: '/admin/accounts',
    AdminCompanyList: '/admin/companies',
    AdminSoftwareClientList: '/admin/software-clients',
    AdminVendorList: '/admin/vendors'
  },
  ...{
    DevLanding: '/dev/home',
    DevFormTest: '/dev/form-test',
    DevButtonsDemo: '/dev/buttons-demo'
  }
} as const;

type RouteKeys = keyof typeof Routes;
type RouteValues = (typeof Routes)[RouteKeys];

export const buildPath = (route: RouteValues, params?: Record<string, string | number>): string => {
  let composedRoute = route as string;

  if (params != null) {
    composedRoute = generatePath(route, params);
  }

  return composedRoute;
};

const buildSearch = (queryParams?: Record<string, string | number>) => {
  if (queryParams == null) {
    return undefined;
  }

  const searchParams = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]) => {
    if (value != null) {
      searchParams.append(key, value.toString());
    }
  });

  const searchString = searchParams.toString();
  if (searchString) {
    return searchString;
  }

  return undefined;
};

type RouteDestinationObject = {
  pathname: RouteValues;
  params?: Record<string, string | number>;
  state?: {};
  search?: Record<string, string | number>;
};

type PreparedDestination = RouteValues | (Omit<RouteDestinationObject, 'search'> & { search?: string });

export type RouteDestination = RouteDestinationObject | RouteValues;

const isModifiedEvent = (event: any) => !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

const isLeftClickEvent = (event: any) => event.button === 0;

export const isRouteMatch = (to: RouteDestination, location: Location<any>) => {
  let path: string;
  if (typeof to === 'string') {
    path = to;
  } else {
    path = to.pathname;
  }

  return matchPath(location.pathname, { exact: true, path: path }) != null;
};

const prepareDest = (to: RouteDestination): PreparedDestination => {
  if (typeof to === 'string') {
    return to;
  }

  if (typeof to === 'object') {
    const built = buildPath(to.pathname, to.params) as RouteValues;
    return {
      ...to,
      pathname: built,
      search: buildSearch(to.search)
    };
  }

  throw new Error('unknown route destination type');
};

export const redirect = (to: RouteDestination) => {
  browserHistory.push(prepareDest(to));
};

export const prepareRouterHrefAttrs = (to: RouteDestination, after: () => void = null) => {
  let preparedTo = prepareDest(to);
  let toStr = '';
  if (typeof preparedTo === 'string') {
    toStr = preparedTo;
    preparedTo = { pathname: toStr as RouteValues };
  } else if (typeof to === 'function' || (typeof to === 'object' && !to.pathname)) {
    throw new Error("Unsupported 'to' object type");
  } else {
    toStr = preparedTo.pathname;
    if (preparedTo.search != null) {
      toStr += `?${preparedTo.search}`;
    }
  }

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    if (event.defaultPrevented) {
      return;
    }

    const tar = event.target as any;
    if (tar.getAttribute('target')) {
      return;
    }

    if (!tar.matches('a, button, .MuiButtonBase-root')) {
      const actualTarget = tar.closest('a, button, .MuiButtonBase-root');
      if (actualTarget.getAttribute('target')) {
        return;
      }
    }

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    event.preventDefault();
    if (typeof preparedTo === 'string') {
      throw new Error("Can't push string to browser history.");
    }

    browserHistory.push(preparedTo);
    useModalStore.getState().afterNavigation();
    if (after != null) {
      after();
    }
  };

  return {
    href: toStr,
    onClick: onClick
  };
};
