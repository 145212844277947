import { Box, Button, Grid, Stack } from '@mui/material';
import { ConfirmationModal, ContentWrapper, NavSlim } from 'components';
import { YesNoIcon } from 'components/YesNoIcon';
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column';
import { DataTable as PrimeDataTable } from 'primereact/datatable';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { apiProvider } from 'shared/api/apiProvider';
import { DocumentTypeModel } from 'shared/api/clients';
import { MuiIcon } from 'shared/icons';
import { showSuccess } from 'shared/utils';
import { DocumentTypeModal } from './DocumentTypeModal';

export const DocumentTypeList = () => {
  const [open, setOpen] = useState(false);

  const [rowId, setRowId] = useState(null);

  const openModal = (id: string = null) => {
    setRowId(id);
    setOpen(true);
  };

  const [{ isLoading, documentTypeData }, setState] = useState<{
    isLoading: boolean;
    documentTypeData: DocumentTypeModel[];
  }>({
    isLoading: true,
    documentTypeData: null
  });

  const load = useCallback(async () => {
    setState({
      isLoading: true,
      documentTypeData: null
    });
    try {
      const documentTypes = await apiProvider.documentTypeClient.getAll();
      setState({
        isLoading: false,
        documentTypeData: documentTypes
      });
    } catch (e) {}
  }, []);

  useEffect(() => {
    setRowId(null);
    void load();
  }, [load]);

  const [filters] = useState({
    name: { operator: 'AND', constraints: [{ value: '', matchMode: 'contains' }] },
    isRequiredExpirationDate: { value: null, matchMode: 'equals' }
  });

  const requiredFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <>
        <div className="p-field-checkbox p-m-0">
          <TriStateCheckbox value={options.value} onChange={(e) => options.filterCallback(e.value)} />
          <label style={{ marginLeft: '0.5rem', lineHeight: 1, marginBottom: 0 }}>Is Required</label>
        </div>
      </>
    );
  };

  const ActionButtons = ({ row, requestRefresh }: { row: DocumentTypeModel; requestRefresh: () => void }) => {
    return (
      <Stack direction={'row'} spacing={2}>
        <Button color="primary" onClick={() => openModal(row.id)}>
          Edit
        </Button>
        <DeleteDocumentTypeButton requestRefresh={requestRefresh} targetUserId={row.id} />
      </Stack>
    );
  };
  return (
    <>
      <Helmet>
        <title>Document Type - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Document Type' }]} />
      <ContentWrapper className="container-property-list">
        <div
          className="d-flex justify-content-between align-items-center pt-3 pb-3 ps-4 pe-4"
          style={{ backgroundColor: 'white' }}
        >
          <Grid container className="align-items-center">
            <Grid item xs={5} md={3}>
              <h5 className="m-0">Document Type</h5>
            </Grid>
            <Grid item xs={7} md={9}>
              <Box className="d-flex align-items-center justify-content-end">
                <Button size={'large'} color="primary" startIcon={<MuiIcon.Add />} onClick={() => openModal(null)}>
                  Add
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
        <PrimeDataTable
          filters={filters as any}
          paginator
          loading={isLoading}
          rows={10}
          value={documentTypeData}
          dataKey={'id'}
        >
          <Column
            sortable
            filter
            showFilterMatchModes={false}
            showFilterOperator={false}
            showAddButton={false}
            filterMatchMode={'contains'}
            field={'name'}
            header={'Name'}
          />
          <Column
            filter
            showFilterMatchModes={false}
            filterElement={requiredFilterTemplate}
            sortable
            header={'Is Required'}
            field={'isRequiredExpirationDate'}
            dataType="boolean"
            body={(row: DocumentTypeModel) => <YesNoIcon value={row.isRequiredExpirationDate} />}
            style={{ width: 150, textAlign: 'center' }}
          />

          <Column
            header={'Actions'}
            body={(row: DocumentTypeModel) => (
              <ActionButtons
                requestRefresh={() => {
                  load();
                  setRowId(row.id);
                }}
                row={row}
              />
            )}
            style={{ width: 210 }}
          />
        </PrimeDataTable>
      </ContentWrapper>

      <DocumentTypeModal
        documentTypeId={rowId}
        open={open}
        onClose={() => {
          setRowId(null);
          setOpen(false);
        }}
        onSuccess={load}
      />
    </>
  );
};

const DeleteDocumentTypeButton = ({
  targetUserId,
  requestRefresh
}: {
  targetUserId: string;
  requestRefresh: () => void;
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const deleteDocumentType = async () => {
    setIsRunning(true);
    try {
      await apiProvider.documentTypeClient.delete(targetUserId);
      requestRefresh();
      showSuccess();
    } catch (err) {}
    setIsRunning(false);
  };

  return (
    <>
      <Button color="error" size="small" onClick={() => setShowConfirmation(true)}>
        Delete
      </Button>
      <ConfirmationModal
        visible={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        onSave={() => void deleteDocumentType()}
        question={'Do you want to delete this Document Type?'}
        running={isRunning}
      />
    </>
  );
};
