import * as Sentry from '@sentry/react';
import { apiProvider } from 'shared/api/apiProvider';
import {
  CompanyContext,
  ImpersonatorData,
  LogInImplicitPayload,
  LookupContainer,
  Membership,
  NotificationStatus,
  NotificationStatusEntityType,
  Property,
  StartSessionPayload,
  User,
  UserDetails,
  Vendor
} from 'shared/api/clients';
import { AuthManager } from './authManager';
import { immer } from 'zustand/middleware/immer';
import { create } from 'zustand';

const staticStore = {
  appVersion: null as string
};

type RooTheme = 'default' | '1836';

type AppStoreData = {
  lookups: LookupContainer;
  userDetails: User;
  memberships: Membership[];
  vendorInfo: Vendor;
  companyContext: CompanyContext;
  notificationStatuses: NotificationStatus[];
  isLoading: boolean;
  dataLoaded: boolean;
  forceEulaModal: boolean;
  breadcrumbTitle: string;
  impersonatingUser: ImpersonatorData;
  theme: RooTheme;
  showUpgradeToast: boolean;
  additionalAuth: boolean;
  actions: AppStoreActions;
  hideReports: boolean;
  showCommissions: boolean;
  hideClients: boolean;
};

type AppStoreActions = {
  initialize: (autoLoginId: string) => Promise<UserDetails>;
  setTheme: (theme: RooTheme) => void;
  onEulaAccepted: () => void;
  onUserUpdated: (newUser: User) => void;
  onEmailConfirmed: () => void;
  onAdditionalAuth: () => void;
  setNotificationStatus: (entityId: string, entityType: NotificationStatusEntityType, enabled: boolean) => void;
  setBreadCrumbTitle: (value: string) => void;
  trackAppVersion: (serverVal: string) => void;
  onVendorUpdated: (vendor: Vendor) => void;
};

export const useAppStore = create<AppStoreData>()(
  immer((set, get) => ({
    lookups: null,
    userDetails: null,
    memberships: null,
    vendorInfo: null,
    companyContext: null,
    notificationStatuses: null,
    isLoading: true as boolean,
    dataLoaded: false,
    forceEulaModal: false,
    breadcrumbTitle: null,
    impersonatingUser: null as ImpersonatorData,
    hideReports: false,
    showCommissions: false,
    hideClients: false,
    theme: 'default',
    showUpgradeToast: false,
    additionalAuth: false,
    actions: {
      initialize: async (autoLoginId: string) => {
        const current = get();
        if (current.dataLoaded) {
          return;
        }

        if (autoLoginId) {
          const result = await apiProvider.authClient.logInImplicit(
            new LogInImplicitPayload({
              userId: autoLoginId
            })
          );

          // if it's not successful, it could mean that the user is already activated
          // in this case, let him go through the rest of the flow rather than sending him to login
          // he might be already logged in
          if (result.success) {
            AuthManager.instance.logIn(result.token, false);
          }
        }

        const ua = navigator.userAgent;
        const [lookups, startSessionResult] = await Promise.all([
          apiProvider.lookupClient.getAll(),
          apiProvider.authClient.startSession(
            new StartSessionPayload({
              userAgent: ua
            })
          )
        ]);

        if (startSessionResult == null) {
          AuthManager.instance.logOut(true);
          return null;
        }

        const [properties, notificationStatuses] = await Promise.all([
          apiProvider.propertiesClient.getAll(startSessionResult.currentUser.user.id),
          apiProvider.usersClient.getNotificationStatuses()
        ]);
        set((curr) => {
          curr.impersonatingUser = startSessionResult.impersonator;
          curr.userDetails = startSessionResult.currentUser.user;
          curr.vendorInfo = startSessionResult.currentUser.vendorInfo;
          curr.memberships = startSessionResult.currentUser.memberships;
          curr.hideClients = startSessionResult.currentUser.hideClients;
          curr.hideReports = startSessionResult.currentUser.hideReports;
          curr.showCommissions = startSessionResult.currentUser.showCommissions;
          curr.lookups = lookups;
          curr.companyContext = startSessionResult.currentUser.companyContext;
          curr.notificationStatuses = notificationStatuses;
          curr.isLoading = false;
          curr.dataLoaded = true;
          if (startSessionResult.currentUser != null) {
            curr.theme =
              (startSessionResult.currentUser.user.managementCompany?.theme as unknown as RooTheme) ?? 'default';
            curr.forceEulaModal =
              startSessionResult.currentUser.user.acceptedEulaAt == null && !AuthManager.instance.isImpersonating();
          }
        });

        Sentry.setUser({
          id: startSessionResult.currentUser.user.id,
          email: startSessionResult.currentUser.user.contactInfo?.email
        });

        return startSessionResult.currentUser;
      },
      setTheme: async (theme: RooTheme) => {
        set((curr) => {
          curr.theme = theme;
        });
      },

      onVendorUpdated: (vendor: Vendor) => {
        set((curr) => {
          if (curr.vendorInfo.id === vendor.id) {
            curr.vendorInfo = vendor;
          }
        });
      },
      onEulaAccepted: () => {
        set((curr) => {
          curr.forceEulaModal = false;
        });
      },
      onUserUpdated: (newUser: User) => {
        set((curr) => {
          curr.userDetails = newUser;
        });
      },
      onEmailConfirmed: () => {
        set((curr) => {
          curr.userDetails.emailConfirmed = true;
        });
      },
      onAdditionalAuth: () => {
        set((curr) => {
          curr.additionalAuth = true;
        });
      },
      setNotificationStatus: (entityId: string, entityType: NotificationStatusEntityType, enabled: boolean) => {
        set((curr) => {
          let existing = curr.notificationStatuses.find((x) => x.entityId === entityId && x.entityType === entityType);
          if (existing == null) {
            existing = new NotificationStatus({
              enabled: enabled,
              entityId,
              entityType,
              userId: null
            });
            curr.notificationStatuses.push(existing);
          }

          existing.enabled = enabled;
        });
      },
      setBreadCrumbTitle: (value: string) => {
        set((curr) => {
          curr.breadcrumbTitle = value;
        });
      },
      trackAppVersion: (serverVal: string) => {
        if (serverVal == null || serverVal.trim() === '') {
          return;
        }

        if (staticStore.appVersion == null) {
          staticStore.appVersion = serverVal;
          return;
        }

        if (staticStore.appVersion !== serverVal) {
          set((x) => {
            x.showUpgradeToast = true;
          });
        }
      }
    }
  }))
);
