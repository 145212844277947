import Button from '@mui/material/Button';
import Grid2 from '@mui/material/Unstable_Grid2';
import { LoaderBig, RooDialog, SaveButtonMui } from 'components';
import { FieldMuiCheckbox, FieldText, useRooForm } from 'components/form';
import { useEffect, useState } from 'react';
import { Alert, Form } from 'react-bootstrap';
import { apiProvider } from 'shared/api/apiProvider';
import { DocumentTypePayload } from 'shared/api/clients';
import { DocumentTypeForm, DocumentTypeSchema, showSuccess } from 'shared/utils';

export const DocumentTypeModal = ({
  documentTypeId,
  open,
  onClose,
  onSuccess
}: {
  documentTypeId: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const defaultValues = {
    name: '',
    isRequiredExpirationDate: false
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { handleSubmit, formState, control, reset } = useRooForm(DocumentTypeSchema, { defaultValues });

  useEffect(() => {
    async function fetchAndSetData() {
      setLoading(true);
      setError(null);

      if (documentTypeId && documentTypeId.trim() !== '') {
        try {
          const documentType = await apiProvider.documentTypeClient.getById(documentTypeId);
          reset({ ...defaultValues, ...documentType });
        } catch (e) {
          setError('Failed to fetch document type');
        }
      } else {
        reset(defaultValues);
      }
      setLoading(false);
    }

    if (open) {
      fetchAndSetData();
    }
    return () => {
      reset(defaultValues);
    };
  }, [documentTypeId, open, reset]);

  const onCloseHandler = () => {
    reset(defaultValues);
    onClose();
  };

  const submit = async (values: DocumentTypeForm) => {
    setLoading(true);
    try {
      const body = new DocumentTypePayload({
        id: documentTypeId,
        name: values.name,
        isRequiredExpirationDate: values.isRequiredExpirationDate
      });

      const result = documentTypeId
        ? await apiProvider.documentTypeClient.update(body)
        : await apiProvider.documentTypeClient.create(body);

      if (result) {
        showSuccess();
        onSuccess();
        onCloseHandler();
      } else {
        setError('Error saving document type!');
      }
    } catch (err) {
      console.error(err);
      setError('Error processing request');
    }
    setLoading(false);
  };

  return (
    <RooDialog open={open} onClose={onCloseHandler}>
      {loading ? (
        <LoaderBig />
      ) : (
        <Form onSubmit={handleSubmit(submit)} key={documentTypeId}>
          <RooDialog.Title onClose={onCloseHandler}>Document Type</RooDialog.Title>
          <RooDialog.Content>
            <Grid2 spacing={2} container>
              <Grid2 xs={12}>
                <FieldText control={control} name="name" required label="Name" placeholder="Name" />
              </Grid2>
              <Grid2 xs={12}>
                <FieldMuiCheckbox
                  control={control}
                  label="Required Date Expiration"
                  name="isRequiredExpirationDate"
                  color="primary"
                />
              </Grid2>
            </Grid2>
            {error && <Alert variant="danger">{error}</Alert>}
          </RooDialog.Content>
          <RooDialog.Actions>
            <SaveButtonMui control={control}>Save</SaveButtonMui>
            <Button
              color="muted"
              disabled={formState.isSubmitting || formState.isValidating}
              type="button"
              onClick={onCloseHandler}
            >
              Cancel
            </Button>
          </RooDialog.Actions>
        </Form>
      )}
    </RooDialog>
  );
};
