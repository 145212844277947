import { Helmet } from 'react-helmet';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { ContentWrapper, IconLink, NavSlim, RooButton, RouterButton } from '../../components';
import { inspectionStatusOptions, rooEnum, useHasInspectionsAccess } from '../../shared/store';
import { useQuery } from '@tanstack/react-query';
import { apiProvider } from '../../shared/api/apiProvider';
import { CenteredLoader } from '../../components/CenteredLoader';
import { Box, Button, Card, CardContent, CardHeader, Stack } from '@mui/material';
import { StatCard } from '../../components/StatCard';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { KeyGetter, rooFmt } from '../../shared/utils';
import { IInspectionSummary, InspectionStatus, InspectionSummary } from '../../shared/api/clients';
import { differenceInCalendarDays } from 'date-fns';
import { Dropdown } from 'primereact/dropdown';
import { MuiIcon } from 'shared/icons';
import { prepareRouterHrefAttrs, Routes } from '../../shared/routing';
import { useDisclosure } from '@roo/lib';
import { CreateInspectionModal } from './Workflow/CreateInspectionModal';

export const InspectionsDashboard = () => {
  const hasAccess = useHasInspectionsAccess();
  return (
    <>
      <Helmet>
        <title>Inspections - Walkthroo</title>
      </Helmet>
      <NavSlim breadcrumbs={[{ text: 'Inspections' }]} />
      <ContentWrapper>{hasAccess && <Body />}</ContentWrapper>
    </>
  );
};

const statKeys = [
  'total',
  'assigned',
  'unassigned',
  'needToAccept',
  'inProgress',
  'notStarted5d',
  'completedLast30d'
] as const;
type StatKey = (typeof statKeys)[number];

const statConfiguration: Record<StatKey, { filter: (summary: InspectionSummary) => boolean; name: string }> = {
  total: {
    name: 'Total',
    filter: () => true
  },
  assigned: {
    name: 'Assigned',
    filter: (ins) => ins.assignedUser != null || ins.assignedVendor != null || ins.assignedCompany != null
  },
  unassigned: {
    name: 'Unassigned',
    filter: (ins) => ins.assignedUser != null && ins.assignedVendor != null && ins.assignedCompany != null
  },
  needToAccept: { name: 'Need to Accept', filter: (ins) => ins.status === InspectionStatus.Invited },
  inProgress: { name: 'In Progress', filter: (ins) => ins.status === InspectionStatus.Started },
  notStarted5d: {
    name: 'Not started after 5d',
    filter: (ins) =>
      ins.status === InspectionStatus.Accepted && differenceInCalendarDays(new Date(), ins.modifiedAt) >= 5
  },
  completedLast30d: {
    name: 'Completed last 30d',
    filter: (ins) => ins.status === InspectionStatus.Done && differenceInCalendarDays(new Date(), ins.modifiedAt) <= 30
  }
} as const;

const Body = () => {
  const { isLoading, data: allData } = useQuery(['inspections', 'list'], () =>
    apiProvider.inspectionClient.listInspections()
  );

  const [originalData, setOriginalData] = useState<InspectionSummary[]>([]);
  const [filteredData, setFilteredData] = useState<InspectionSummary[]>([]);
  const [currentStat, setCurrentStat] = useState<StatKey | null>(null);

  React.useEffect(() => {
    if (allData) {
      setOriginalData(allData);
      const initialFilter = allData.filter((ins) => ins.status !== InspectionStatus.Done); // Verifique se esta linha está sendo executada corretamente
      setFilteredData(initialFilter);
    }
  }, [allData]);

  const handleFilterHeader = (filter: StatKey) => {
    const newData = originalData.filter(statConfiguration[filter].filter);
    setFilteredData(newData);
  };

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Stack gap={4}>
      <Stack>
        <h3 style={{ fontWeight: 400, marginTop: '24px', marginBottom: '10px' }}>Summary</h3>
        <Box sx={{ backgroundColor: 'white' }}>
          <Box
            sx={{
              display: 'grid',
              gridAutoRows: '1fr',
              gridTemplateColumns: 'repeat(auto-fit, minmax(180px, 1fr));'
            }}
          >
            {statKeys.map((k) => (
              <StatCard
                stat={{
                  displayName: statConfiguration[k].name,
                  value: originalData.filter(statConfiguration[k].filter).length,
                  delayedCount: 0,
                  assigneeBadge: null,
                  hasDetails: true,
                  displayDecimals: 0,
                  emphasized: false
                }}
                isLoading={false}
                isSelected={currentStat === k}
                onClick={() => handleFilterHeader(k)}
              />
            ))}
          </Box>
        </Box>
      </Stack>
      <InspectionList
        originalData={originalData}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        currentStat={currentStat}
        setCurrentStat={setCurrentStat}
      />
    </Stack>
  );
};

const InspectionList = ({
  originalData,
  filteredData,
  setFilteredData,
  currentStat,
  setCurrentStat
}: {
  originalData: InspectionSummary[];
  filteredData: InspectionSummary[];
  setFilteredData: React.Dispatch<React.SetStateAction<InspectionSummary[]>>;
  currentStat: StatKey | null;
  setCurrentStat: (k: StatKey) => void;
}) => {
  const handleStatusChange = (
    e: { value: InspectionStatus | null },
    options: { filterCallback: (value: string) => void }
  ) => {
    const applyDateFilter = e.value === InspectionStatus.LessThan30 || e.value === InspectionStatus.LessThan60;

    const filteredDataDone = originalData.filter((ins) => {
      if (!applyDateFilter) return true;

      const daysSinceModification = differenceInCalendarDays(new Date(), new Date(ins.modifiedAt));
      if (ins.status === InspectionStatus.Done || ins.status === e.value) {
        switch (e.value) {
          case InspectionStatus.LessThan30:
            return daysSinceModification <= 30;
          case InspectionStatus.LessThan60:
            return daysSinceModification <= 400;
          default:
            return true;
        }
      }

      return false;
    });

    let filteredData =
      e.value === undefined || e.value === null ? originalData : originalData.filter((ins) => ins.status === e.value);

    setFilteredData(applyDateFilter ? filteredDataDone : filteredData);

    if (options && options.filterCallback) {
      options.filterCallback(e.value ? e.value.toString() : '');
    }
  };

  return (
    <Card>
      <CardHeader
        title={currentStat == null ? 'Inspections' : `Showing only: ${statConfiguration[currentStat].name}`}
        action={
          currentStat == null ? (
            <CreateInspectionButton />
          ) : (
            <RooButton onClick={() => setCurrentStat(null)} variant={'link'} icon={'undo'}>
              View All
            </RooButton>
          )
        }
      />
      <CardContent>
        <div className={'workorder-container'} style={{ width: '100%' }}>
          <DataTable
            value={filteredData}
            sortField={'createdAt'}
            sortOrder={-1}
            paginator
            rows={10}
            rowsPerPageOptions={[10, 25, 50, 100]}
            emptyMessage={<p className={'text-center'}>No Inspections</p>}
            size={'small'}
            dataKey={'id'}
          >
            <Column
              header={'Inspection ID'}
              sortable
              field={'friendlyId'}
              body={(row: InspectionSummary) => (
                <IconLink
                  style={{ whiteSpace: 'nowrap' }}
                  to={{ pathname: Routes.InspectionView, params: { id: row.id } }}
                  text={row.friendlyId}
                />
              )}
            />
            <Column
              field={'property.address.address1'}
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'Address 1'}
            />
            <Column
              field="property.address.address2"
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'Address 2'}
            />

            <Column
              field="status"
              header="Status"
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              filter
              filterElement={(options) => (
                <Dropdown
                  value={Number(options.value)}
                  options={inspectionStatusOptions}
                  onChange={(e) => handleStatusChange(e, options)}
                  placeholder="Select a Status"
                  className="p-column-filter"
                  showClear
                />
              )}
              body={(row: IInspectionSummary) => rooEnum.inspectionStatus.display(row.status)}
              sortable
            />
            <Column
              field={'assigneeName'}
              filter
              showFilterMatchModes={false}
              showFilterOperator={false}
              showAddButton={false}
              sortable
              header={'Assignee'}
            />
            <Column
              field={'createdAt'}
              sortable
              header={'Created'}
              dataType={'date'}
              body={(row: InspectionSummary) => rooFmt.date(row.createdAt)}
            />
            <Column
              field={'modifiedAt'}
              sortable
              header={'Modified'}
              dataType={'date'}
              body={(row: InspectionSummary) => rooFmt.date(row.modifiedAt)}
            />
            <Column
              body={(row: InspectionSummary) => (
                <Stack direction={'row'} flexWrap={'nowrap'} spacing={1}>
                  {row.status === InspectionStatus.Done && row.lastRenderedPdf && (
                    <RooButton
                      style={{ width: '80px' }}
                      as={'a'}
                      href={apiProvider.fileUrls.download(row.lastRenderedPdf.id)}
                      size={'sm'}
                      icon={'download'}
                    >
                      PDF
                    </RooButton>
                  )}
                  {row.status === InspectionStatus.Done && (
                    <RooButton
                      style={{ width: '80px' }}
                      as={'a'}
                      {...prepareRouterHrefAttrs({ pathname: Routes.InspectionReport, params: { id: row.id } }, null)}
                      size={'sm'}
                      icon={'eye'}
                    >
                      Report
                    </RooButton>
                  )}
                </Stack>
              )}
            />
          </DataTable>
        </div>
      </CardContent>
    </Card>
  );
};

const CreateInspectionButton = () => {
  const disclosure = useDisclosure(false);
  return (
    <>
      <Button size={'large'} color="primary" onClick={disclosure.open} startIcon={<MuiIcon.Add />}>
        Create Inspection
      </Button>
      <CreateInspectionModal visible={disclosure.isOpen} onCloseRequest={disclosure.close} />
    </>
  );
};
