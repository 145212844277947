import { fileUtils, UploaderFile, useFileArea } from './FilePicker.store';
import { RooDialog } from '../../RooDialog';
import { Button, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { Center } from '../../Center';
import { useEffect, useMemo } from 'react';
import { rooFmt } from '../../../shared/utils';
import { prettierBytes, truncateMiddle, useBasicFileDrop } from './utils';
import { getIconByMime } from './getIconByMime';
import { MuiIcon } from 'shared/icons';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import { apiProvider } from 'shared/api/apiProvider';
import { format } from 'date-fns';
import Grid2 from '@mui/material/Unstable_Grid2';
import { FieldDatePicker, FieldSingleSelect } from 'components/form';
import { useHasCapability } from 'shared/store';
import { CapabilityType } from '@roo/api';

const fetchDocumentTypes = async () => {
  const data = await apiProvider.documentTypeClient.getAll();
  return data.map((x) => ({
    label: x.name,
    value: x.id,
    isRequired: x.isRequiredExpirationDate
  }));
};

export const ViewFileList = ({ requireDocType }: { requireDocType: boolean }) => {
  useBasicFileDrop();
  const files = useFileArea((x) => x.files);
  const { canAddMore } = useFileArea((x) => x.computed);
  const { requestView } = useFileArea((x) => x.actions);
  const { updateMetaDataFile } = useFileArea((x) => x.actions);
  const { startUpload } = useFileArea((x) => x.actions);

  const { control, handleSubmit, watch, setValue, getValues } = useForm({
    defaultValues: {
      documentTypeId: '',
      expirationDate: null
    }
  });

  const documentType = watch('documentTypeId');
  const expirationDate = watch('expirationDate');

  const {
    data: documentTypes,
    isLoading,
    error
  } = useQuery(['documentTypes'], fetchDocumentTypes, {
    staleTime: 5 * 60 * 1000,
    cacheTime: 15 * 60 * 1000,
    onSuccess: (data) => {
      if (data?.length) {
        const initialDocType = data.find((d) => d.value === getValues('documentTypeId'));
        if (initialDocType && !initialDocType.isRequired) {
          setValue('expirationDate', null);
        }
      }
    },
    onError: (err) => {
      console.error('Failed to fetch document types:', err);
    }
  });

  useEffect(() => {
    if (expirationDate || documentType) {
      updateMetaDataFile(expirationDate ? format(expirationDate, 'yyyy-MM-dd HH:mm') : null, documentType);
    }
  }, [expirationDate, documentType, updateMetaDataFile]);

  useEffect(() => {
    const currentDocType = documentTypes?.find((d) => d.value === documentType);
    if (currentDocType && !currentDocType.isRequired) {
      setValue('expirationDate', null);
    }
  }, [documentType, documentTypes, setValue]);

  const onSubmit = (formData: any) => {
    startUpload();
  };

  return (
    <Grid2 xs={12} xl={12} spacing={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RooDialog.Content>
          <Stack spacing={4}>
            {requireDocType && (
              <VendorFieldsWithDocument
                control={control}
                data={documentTypes}
                isLoading={isLoading}
                documentType={documentType}
                setValue={setValue}
              />
            )}

            <Grid2 md={12} xs={12}>
              {files.map((x) => (
                <FileEntry key={x.assetId} file={x} />
              ))}
            </Grid2>

            {canAddMore && (
              <Center>
                <Button color={'info'} startIcon={<MuiIcon.Add />} onClick={() => requestView('source-selector')}>
                  Add More
                </Button>
              </Center>
            )}
          </Stack>
        </RooDialog.Content>

        <Grid2 md={12} xs={12}>
          <RooDialog.Actions>
            <ViewListAction />
          </RooDialog.Actions>
        </Grid2>
      </form>
    </Grid2>
  );
};

const ViewListAction = () => {
  const { isUploading, pendingCount, successCount, canClose } = useFileArea((x) => x.computed);
  const { requestClose } = useFileArea((x) => x.actions);

  return (
    <Center px={4} sx={{ height: 50, width: '100%' }}>
      {(isUploading || pendingCount > 0) && (
        <Button
          id="btnUpload"
          type="submit" // Change button to type "submit" to trigger form submission
          disabled={isUploading || pendingCount === 0}
          startIcon={!isUploading ? <MuiIcon.CloudUpload /> : <CircularProgress size={22} />}
          fullWidth
          size={'large'}
        >
          {isUploading ? 'Uploading...' : `Upload ${rooFmt.counted(pendingCount, 'File', 'Files')}`}
        </Button>
      )}
      {!isUploading && pendingCount === 0 && successCount > 0 && canClose && (
        <Button fullWidth size={'large'} onClick={requestClose}>
          Done
        </Button>
      )}
    </Center>
  );
};

const FileEntry = ({ file }: { file: UploaderFile }) => {
  const { removeFile } = useFileArea((x) => x.actions);
  let size = prettierBytes(file.rawFile.size);
  if (file.compressedFile != null) {
    size += ` (${prettierBytes(file.compressedFile.size)} compressed)`;
  }
  return (
    <Card variant={'outlined'} sx={{ p: 1 }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack direction={'row'} alignItems={'center'} spacing={2}>
          {file.thumbnailUrl != null && <FilePreviewImage file={file} />}
          {file.thumbnailUrl == null && <FilePreviewIcon file={file} />}

          <Stack>
            <Typography title={file.fileName}>{truncateMiddle(file.fileName, 30)}</Typography>
            <Typography variant={'caption'}>{size}</Typography>
            {file.state === 'pending' && <Typography variant={'caption'}>Pending upload</Typography>}
            {file.state === 'success' && (
              <Typography color={'success.main'} variant={'caption'}>
                Uploaded successfully!
              </Typography>
            )}
            {(file.state === 'uploading' || file.state === 'compressing') && (
              <Typography color={'info.main'} variant={'caption'}>
                Uploading...
              </Typography>
            )}
            {file.state === 'error' && (
              <Typography color={'error.main'} variant={'caption'}>
                {file.errorMessage ?? 'Failed to upload file'}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack>
          {fileUtils.canRemove(file) && (
            <IconButton color={'error'} onClick={() => removeFile(file.assetId)}>
              <MuiIcon.Delete />
            </IconButton>
          )}
        </Stack>
      </Stack>
    </Card>
  );
};

const FilePreviewImage = ({ file }: { file: UploaderFile }) => {
  return <img alt={''} width={64} height={64} src={file.thumbnailUrl} />;
};

const FilePreviewIcon = ({ file }: { file: UploaderFile }) => {
  const { icon, color } = useMemo(() => getIconByMime(file.rawFile.type), [file.rawFile.type]);
  return (
    <Center width={64} height={64} bgcolor={color} borderRadius={1}>
      <Center sx={{ width: '55%', height: '76%' }} bgcolor={'white'}>
        {icon}
      </Center>
    </Center>
  );
};

const VendorFieldsWithDocument = ({
  control,
  data,
  isLoading,
  documentType,
  setValue
}: {
  control: any;
  data: any[];
  isLoading: boolean;
  documentType: string;
  setValue: any;
}) => (
  <>
    <FieldSingleSelect
      control={control}
      required
      name="documentTypeId"
      options={data}
      isDisabled={isLoading}
      label="Document Type"
    />
    <Controller
      control={control}
      name="expirationDate"
      rules={{
        required: data?.find((d) => d.value === documentType)?.isRequired ? 'Expiration date is required' : false
      }}
      render={({ field }) => (
        <FieldDatePicker
          {...field}
          control={control}
          name="expirationDate"
          minDate={new Date()}
          dateFormat="M/d/yyyy"
          placeholderText="Expiration Data"
          label="Expiration Data"
        />
      )}
    />
  </>
);
