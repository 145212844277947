import { createStoreContext } from '@roo/lib';
import * as Sentry from '@sentry/react';
import { ReactNode } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { AttachmentEntityType, AttachmentFile, FileVisibility } from '../../../shared/api/clients';
import { computed } from '../../../shared/external/zustand-computed/zustand-computed';
import { AuthManager } from '../../../shared/store';
import { compressImage } from './utils';

export type FileUploadMeta = {
  entityType: AttachmentEntityType;
  entityId?: string;
  secondaryEntityType?: AttachmentEntityType;
  secondaryEntityId?: string;
  visibility?: FileVisibility;
  galleryEntityType?: AttachmentEntityType;
  galleryEntityId?: string;
  expirationDate?: string;
  documentTypeId?: string;
};

type UploaderView = 'source-selector' | 'file-list' | 'gallery' | 'collage';
export type UploaderFile = {
  assetId: string;
  fileName: string;
  rawFile: Blob;

  state: 'pending' | 'compressing' | 'uploading' | 'error' | 'success';
  compressedFile?: File;
  progress?: number;
  errorMessage?: string;
  thumbnailUrl?: string;
  uploadResult?: AttachmentFile;
};
export type AllowedFileType = 'image' | 'document';

type UploaderStoreType = {
  view: UploaderView;
  files: UploaderFile[];
  maxFiles: number;
  allowedFileTypes: AllowedFileType[];
  providerLoading: boolean;
  permissionsNoticeMessage?: string;
  meta: FileUploadMeta;
  actions: {
    requestView: (view: UploaderView) => void;
    requestClose: () => void;
    addFiles: (files: UploaderFile[]) => void;
    removeFile: (assetId: string) => void;
    startUpload: () => void;
    setDropAction: (action: (files: File[]) => void) => void;
    onDrop: (files: File[]) => void;
    updateMetaDataFile: (expirationDate: string, documentTypeId: string) => void;
  };
  internals: {
    requestClose: () => void;
    onAppend?: (...items: AttachmentFile[]) => void;
    onFileDrop?: (files: File[]) => void;
  };
};

const createUploaderStore = ({
  allowedFileTypes,
  maxFiles,
  requestClose,
  meta,
  onAppend
}: {
  allowedFileTypes: AllowedFileType[];
  maxFiles: number;
  requestClose: () => void;
  meta: FileUploadMeta;
  onAppend?: (...items: AttachmentFile[]) => void;
}) =>
  create<UploaderStoreType>()(
    computed(
      immer((set, get, store) => ({
        files: [],
        view: 'source-selector',
        allowedFileTypes,
        maxFiles,
        meta,
        providerLoading: false,
        actions: {
          requestView: (view: UploaderView) => {
            set((x) => {
              x.view = view;
            });
          },
          addFiles: (files) => {
            set((x) => {
              for (const file of files) {
                const match = x.files.find((x) => x.assetId === file.assetId);
                x.files.push(file);

                if (file.state !== 'success') {
                  if (file.rawFile.type.startsWith('image/') && file.state === 'pending') {
                    setTimeout(() => {
                      void generateThumbnail(file.assetId, store as StoreApiType);
                    });
                  }

                  if (x.files.length > x.maxFiles) {
                    file.state = 'error';
                    file.errorMessage = 'Too many files';
                  }

                  if (match != null) {
                    file.state = 'error';
                    file.errorMessage = 'File already added';
                  }

                  let isAllowed = false;
                  for (const allowed of x.allowedFileTypes) {
                    switch (allowed) {
                      case 'image':
                        if (file.rawFile.type.startsWith('image/')) {
                          isAllowed = true;
                        }
                        break;
                      case 'document':
                        isAllowed = true;
                        break;
                    }
                  }

                  if (!isAllowed) {
                    file.state = 'error';
                    file.errorMessage = 'File type not allowed';
                  }
                } else {
                  x.internals.onAppend?.(file.uploadResult);
                }
              }

              // keep them in the selector on cancel
              if (x.files.length > 0 && files.length > 0) {
                x.view = 'file-list';
              }
            });
          },
          startUpload: () => {
            const state = get();
            for (const file of state.files.filter((x) => x.state === 'pending')) {
              void runUpload(file.assetId, store as StoreApiType);
            }
          },
          removeFile: (assetId: string) => {
            set((x) => {
              const idx = x.files.findIndex((x) => x.assetId === assetId);
              if (idx >= 0) {
                const file = x.files[idx];
                if (fileUtils.canRemove(file)) {
                  if (file.thumbnailUrl != null) {
                    URL.revokeObjectURL(file.thumbnailUrl);
                  }
                  x.files.splice(idx, 1);
                }
              }

              if (x.files.length === 0) {
                x.view = 'source-selector';
              }
            });
          },
          requestClose: () => {
            get().internals.requestClose?.();
          },
          setDropAction: (action) =>
            set((x) => {
              x.internals.onFileDrop = action;
            }),
          onDrop: (files) => {
            const state = get();
            if (files == null || files.length === 0 || state.internals.onFileDrop == null) {
              return;
            }

            state.internals.onFileDrop(files);
          },
          updateMetaDataFile: (expirationDate: string, documentTypeId: string) => {
            set((x) => {
              x.meta.expirationDate = expirationDate;
              x.meta.documentTypeId = documentTypeId;
            });
          }
        },
        internals: {
          requestClose:
            requestClose == null
              ? null
              : () => {
                  set((x) => {
                    for (const file of x.files) {
                      if (file.thumbnailUrl != null) {
                        URL.revokeObjectURL(file.thumbnailUrl);
                        file.thumbnailUrl = null;
                      }
                    }
                  });
                  requestClose();
                },
          onAppend
        }
      })),
      (state) => ({
        computed: {
          canAddMore: state.maxFiles == null || state.files.length < state.maxFiles,
          canAddMultiple: state.maxFiles == null || state.maxFiles - state.files.length > 1,
          isUploading: state.files.some((x) => x.state === 'uploading' || x.state === 'compressing'),
          pendingCount: state.files.filter((x) => x.state === 'pending').length,
          successCount: state.files.filter((x) => x.state === 'success').length,
          canClose: state.internals.requestClose != null,
          canDrop: state.internals.onFileDrop != null,
          hasGallery: state.meta.galleryEntityId != null && state.meta.galleryEntityType != null
        }
      })
    )
  );

const runUpload = async (fileId: string, store: StoreApiType) => {
  const file = getFile(fileId, store.getState());
  if ((file.rawFile.type ?? '').startsWith('image/')) {
    try {
      await resizeImage(fileId, store);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  registerWrapped(fileId, store);
};

const registerWrapped = (fileId: string, store: StoreApiType) => {
  try {
    registerXhr(fileId, store);
  } catch (e) {
    Sentry.captureException(e);
  }
};

const resizeImage = async (fileId: string, store: StoreApiType) => {
  updateFile(fileId, store, (file) => {
    file.state = 'compressing';
  });
  try {
    const file = getFile(fileId, store.getState());
    const compressed = await compressImage(file.rawFile, 0.6, 1920, 1920);
    updateFile(fileId, store, (file) => {
      // @ts-ignore
      file.compressedFile = compressed;
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};

const generateThumbnail = async (fileId: string, store: StoreApiType) => {
  try {
    const file = getFile(fileId, store.getState());
    const compressed = await compressImage(file.rawFile, 0.8, 256, 256);
    updateFile(fileId, store, (file) => {
      file.thumbnailUrl = URL.createObjectURL(compressed);
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};

const registerXhr = (fileId: string, store: StoreApiType) => {
  const xhr = new XMLHttpRequest();
  xhr.open('POST', (process.env.REACT_APP_BASE_URL ?? '') + '/files/upload');
  xhr.setRequestHeader('Authorization', `Bearer ${AuthManager.instance.getToken()}`);

  xhr.onload = () => {
    store.setState((x) => {
      const file = getFile(fileId, x);
      try {
        const response = AttachmentFile.fromJS(JSON.parse(xhr.response));
        file.progress = 1;
        file.state = 'success';
        file.uploadResult = response;

        x.internals.onAppend?.(response);
        if (
          x.internals.requestClose != null &&
          x.maxFiles === 1 &&
          !x.files.some((x) => x.state === 'pending' || x.state === 'uploading' || x.state === 'compressing')
        ) {
          x.internals.requestClose();
        }
      } catch (e) {
        file.state = 'error';
        Sentry.captureException(e);
      }
    });
  };

  xhr.onerror = (_) => {
    updateFile(fileId, store, (file) => {
      file.state = 'error';
    });
  };

  xhr.ontimeout = (_) => {
    updateFile(fileId, store, (file) => {
      file.state = 'error';
    });
  };

  if (xhr.upload) {
    xhr.upload.onprogress = ({ total, loaded }) => {
      updateFile(fileId, store, (file) => {
        file.progress = loaded / total;
      });
    };
  }

  const state = store.getState();
  const file = getFile(fileId, state);

  const formData = new FormData();

  formData.append('file', file.compressedFile ?? file.rawFile, file.fileName);
  const keys: (keyof FileUploadMeta)[] = [
    'entityId',
    'entityType',
    'secondaryEntityId',
    'secondaryEntityType',
    'visibility',
    'expirationDate',
    'documentTypeId'
  ];

  for (const key of keys) {
    const val = state.meta[key]?.toString();
    if (val != null) {
      formData.append(key, val);
    }
  }
  updateFile(fileId, store, (file) => {
    file.state = 'uploading';
  });

  xhr.send(formData);
};

const getFile = (fileId: string, state: UploaderStoreType) => state.files.find((x) => x.assetId === fileId);
const updateFile = (fileId: string, store: StoreApiType, setter: (state: UploaderFile) => void) => {
  store.setState((x) => {
    const file = getFile(fileId, x);
    setter(file);
  });
};

type StoreApiType = ReturnType<typeof createUploaderStore>;
export const {
  Provider: FileUploaderStoreProvider,
  useStore: useFileArea,
  useStoreApi: useFileAreaApi
} = createStoreContext<StoreApiType>();

export const FileUploaderContext = ({
  children,
  allowedFileTypes,
  maxFiles,
  requestClose,
  meta,
  onAppend
}: {
  children: ReactNode;
  allowedFileTypes: AllowedFileType[];
  maxFiles: number;
  requestClose: () => void;
  meta: FileUploadMeta;
  onAppend?: (...items: AttachmentFile[]) => void;
}) => {
  return (
    <FileUploaderStoreProvider
      createStore={() =>
        createUploaderStore({
          allowedFileTypes: allowedFileTypes ?? ['image', 'document'],
          maxFiles,
          requestClose,
          meta,
          onAppend
        })
      }
    >
      {children}
    </FileUploaderStoreProvider>
  );
};

export const fileUtils = {
  canRemove: (file: UploaderFile) => file.state === 'error' || file.state === 'pending'
};
