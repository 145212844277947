import { UploaderFile, useFileArea } from './FilePicker.store';
import { useEffect } from 'react';
import CompressorJS from 'compressorjs';
import { makeUuid } from '@roo/lib';

export const useBasicFileDrop = () => {
  const { setDropAction, addFiles } = useFileArea((x) => x.actions);

  useEffect(() => {
    setDropAction((files) => {
      const mapped = files.map(
        (x) => ({ rawFile: x, fileName: x.name, assetId: makeUuid(), state: 'pending' }) satisfies UploaderFile
      );
      addFiles(mapped);
    });
    return () => setDropAction(null);
  }, []);
};

export const compressImage = (blob: Blob, quality: number, width: number, height: number) => {
  return new Promise<File | Blob>((resolve, reject) => {
    new CompressorJS(blob, {
      success: resolve,
      error: reject,
      maxWidth: width,
      maxHeight: height,
      quality: quality
    });
  });
};

// taken from uppy
const separator = '...';
export const truncateMiddle = (str: string, maxLength: number) => {
  if (maxLength === 0) {
    return '';
  }
  if (str.length <= maxLength) {
    return str;
  }

  if (maxLength <= separator.length + 1) return `${str.slice(0, maxLength - 1)}…`;

  const charsToShow = maxLength - separator.length;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return str.slice(0, frontChars) + separator + str.slice(-backChars);
};

// taken from https://github.com/transloadit/monolib/blob/main/packages/prettier-bytes/src/prettierBytes.ts
export const prettierBytes = (num: number) => {
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError(`Expected a number, got ${typeof num}`);
  }

  const neg = num < 0;
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return `${(neg ? '-' : '') + num} B`;
  }

  const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1);
  num = Number(num / Math.pow(1024, exponent));
  const unit = units[exponent];

  if (num >= 10 || num % 1 === 0) {
    // Do not show decimals when the number is two-digit, or if the number has no
    // decimal component.
    return `${(neg ? '-' : '') + num.toFixed(0)} ${unit}`;
  }

  return `${(neg ? '-' : '') + num.toFixed(1)} ${unit}`;
};
