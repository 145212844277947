import React from 'react';
import { FieldValues, useController } from 'react-hook-form';
import { ErrorMessage } from 'components/form/ErrorMessage';
import { DropdownOption } from 'shared/store';
import { RooSelect, RooSelectProps } from 'components/RooSelect';
import { ControlProps } from 'components/form/types';
import { FormGroup } from 'react-bootstrap';
import { FormGroupProps } from 'react-bootstrap/FormGroup';
import { RooFormLabel } from './RooFormLabel';

type BaseProps<TVal> = Omit<RooSelectProps<TVal>, 'id' | 'type' | 'name' | 'onChange' | 'value' | 'onBlur' | 'isMulti'>;
type FinalProps<TForm extends FieldValues, TVal> = ControlProps<TForm> &
  BaseProps<TVal> & {
    equalityFn?: (a: TVal, b: TVal) => boolean;
    groupProps?: FormGroupProps;
  };
export type FieldSingleSelectProps<TForm extends FieldValues, TVal> = FinalProps<TForm, TVal>;

export const FieldSingleSelect = <TForm extends FieldValues, TVal>({
  name,
  options,
  control,
  label,
  equalityFn = (a, b) => a === b,
  groupProps,
  required,
  ...rest
}: FinalProps<TForm, TVal>) => {
  const {
    field: { value, onBlur, onChange },
    fieldState: { error, isTouched }
  } = useController({
    control,
    name,
    rules: { required: required ? 'Required' : undefined }
  });

  let val: any = null;
  if (value != null) {
    val = options?.find((x: any) => {
      return equalityFn(x.value, value as TVal);
    });
  }

  const isInvalid = required && !val && (isTouched || error !== undefined);

  return (
    <FormGroup {...(groupProps ?? {})}>
      <RooFormLabel label={label} name={name} required={required} />
      <RooSelect
        name={name}
        id={name}
        invalid={isInvalid}
        isMulti={false}
        options={options}
        value={val}
        onBlur={onBlur}
        onChange={(selectedOption) => {
          const selectedValue = selectedOption as DropdownOption<TVal>;
          // @ts-ignore
          onChange(selectedValue?.value);
        }}
        menuPortalTarget={document.body}
        {...rest}
      />
      {isInvalid && error?.message && (
        <div style={{ display: 'block' }} className={'invalid-feedback'}>
          {error.message}
        </div>
      )}
    </FormGroup>
  );
};
