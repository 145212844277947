import { Legal } from 'pages/AboutUs/Legal';
import { AccountsReceivable } from 'pages/Reports/AccountsReceivable';
import { CompanyList } from 'pages/Admin/CompanyList/CompanyList';
import { Layout } from 'Layout/Layout';
import { PropertyCreate } from 'pages/Properties/PropertyCreate';
import { PropertyEdit } from 'pages/Properties/PropertyEdit';
import { PropertyList } from 'pages/Properties/PropertyList';
import { PropertyView } from 'pages/Properties/PropertyView';
import { Route, Switch } from 'react-router';
import { Routes } from 'shared/routing';
import { Feedback } from 'components';
import { EditProfile } from 'pages/UserProfile/EditProfile/EditProfile';
import { ViewProfile } from 'pages/UserProfile/ViewProfile/ViewProfile';
import { ManagerList } from 'pages/UserLists/ManagerList';
import { OwnerList } from 'pages/UserLists/OwnerList';
import { TenantList } from 'pages/UserLists/TenantList';
import { VendorCreate } from 'pages/UserLists/Vendors/VendorCreate';
import { VendorList } from 'pages/UserLists/Vendors/VendorList';
import { CreateWorkorder } from 'pages/Workorders/CreateWorkorder';
import { ViewIssue } from 'pages/Workorders/ViewIssue/ViewIssue';
import { ViewWorkorder } from 'pages/Workorders/ViewWorkorder/ViewWorkorder';
import { EmailConfirmation } from 'pages/Auth/EmailConfirmation';
import { EulaModal } from 'components/modals/EulaModal';
import { LandingPage } from './LandingPage';
import { Login } from 'pages/Auth/Login';
import { NotFound } from './NotFound';
import { PageInitializer } from './PageInitializer';
import { Register } from 'pages/Auth/Register';
import { RequestPasswordReset } from 'pages/Auth/RequestPasswordReset';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { TwoFactor } from 'pages/Auth/TwoFactor';
import { AccountsPayable } from '../pages/Reports/AccountsPayable';
import { ProfitLoss } from '../pages/Reports/ProfitLoss';
import { VendorClientsPage } from '../pages/VendorClients/VendorClientsPage';
import { BidCompare } from 'pages/Workorders/ViewIssue/Documents/BidCompare/BidCompare';
import { CompanyProfilePage } from '../pages/CompanyProfile/CompanyProfilePage';
import { AccountsList } from '../pages/Admin/AccountsList/AccountsList';
import { FormTestPage } from '../pages/Dev/FormTest/FormTestPage';
import { DevLandingPage } from '../pages/Dev/DevLandingPage';
import { DevButtonsDemoPage } from '../pages/Dev/ButtonsDemo/DevButtonsDemoPage';
import { VendorProfilePage } from '../pages/VendorProfile/VendorProfilePage';
import { AppNudgeModal } from '../components/modals/AppNudgeModal';
import { PayInvoice } from 'pages/Auth/PayInvoice';
import { Payees } from '../pages/Reports/Payees';
import { InspectionsDashboard } from '../pages/Inspections/InspectionsDashboard';
import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import { InspectionSummaryPage } from '../pages/Inspections/InspectionSummaryPage';
import { InspectionReportPage } from '../pages/Inspections/InspectionReportPage';
import { LoginMagicLink } from '../pages/Auth/LoginMagicLink';
import { SoftwareClientList } from '../pages/Admin/SoftwareClientList/SoftwareClientList';
import { BidCompareReadonlyPage } from '../pages/Workorders/ViewIssue/Documents/BidCompareReadonly/BidCompareReadonlyPage';
import { CommissionsReportPage } from '../pages/Comissions/CommissionsReportPage';
import { AdminVendorList } from '../pages/Admin/VendorList/VendorList';
import { DocumentTypeList } from 'pages/DocumentType/DocumentTypeList';

export const FullPageApp = () => {
  return (
    <>
      <EulaModal />
      <AppNudgeModal />
      <Feedback />
      <Switch>
        <Route path={Routes.Landing} exact component={LandingPage} />
        <Route path={Routes.LogIn} exact component={Login} />
        <Route path={Routes.LogInMagicLink} exact component={LoginMagicLink} />
        <Route path={Routes.RequestPasswordReset} exact component={RequestPasswordReset} />
        <Route path={Routes.ResetPassword} exact component={ResetPassword} />
        <Route path={Routes.TwoFactor} exact component={TwoFactor} />
        <Route path={Routes.Register} exact component={Register} />
        <Route path={Routes.PayInvoice} exact component={PayInvoice} />
        <Route
          component={() => {
            return (
              <PageInitializer>
                <Layout>
                  <Switch>
                    <Route path={Routes.Dashboard} exact component={DashboardPage} />
                    <Route path={Routes.DashboardAlt} exact component={DashboardPage} />
                    <Route path={Routes.InspectionsDashboard} exact component={InspectionsDashboard} />
                    <Route path={Routes.InspectionReport} exact component={InspectionReportPage} />
                    <Route path={Routes.InspectionView} exact component={InspectionSummaryPage} />
                    <Route path={Routes.WorkorderCreate} exact component={CreateWorkorder} />
                    <Route path={Routes.IssueView} exact component={ViewIssue} />
                    <Route path={Routes.IssueBidCompare} exact component={BidCompare} />
                    <Route path={Routes.IssueBidCompareReadonly} exact component={BidCompareReadonlyPage} />
                    <Route path={Routes.WorkorderView} exact component={ViewWorkorder} />
                    <Route path={Routes.PropertyList} exact component={PropertyList} />
                    <Route path={Routes.PropertyCreate} exact component={PropertyCreate} />
                    <Route path={Routes.PropertyEdit} exact component={PropertyEdit} />
                    <Route path={Routes.PropertyView} exact component={PropertyView} />
                    <Route path={Routes.NewUserProfile} exact component={EditProfile} />
                    <Route path={Routes.CurrentUserProfile} exact component={EditProfile} />
                    <Route path={Routes.UserProfile} exact component={ViewProfile} />
                    <Route path={Routes.CompanyProfile} exact component={CompanyProfilePage} />
                    <Route path={Routes.VendorProfile} exact component={VendorProfilePage} />
                    <Route path={Routes.ConfirmEmail} exact component={EmailConfirmation} />
                    <Route path={Routes.VendorList} exact component={VendorList} />
                    <Route path={Routes.VendorCreate} exact component={VendorCreate} />
                    <Route path={Routes.ManagerList} exact component={ManagerList} />
                    <Route path={Routes.DocumentTypeList} exact component={DocumentTypeList} />
                    <Route path={Routes.TenantList} exact component={TenantList} />
                    <Route path={Routes.OwnerList} exact component={OwnerList} />
                    <Route path={Routes.AdminAccountsList} exact component={AccountsList} />
                    <Route path={Routes.AdminCompanyList} exact component={CompanyList} />
                    <Route path={Routes.AdminSoftwareClientList} exact component={SoftwareClientList} />
                    <Route path={Routes.AdminVendorList} exact component={AdminVendorList} />
                    <Route path={Routes.AboutUsLegal} exact component={Legal} />
                    <Route path={Routes.AccountsReceivable} exact component={AccountsReceivable} />
                    <Route path={Routes.AccountsPayable} exact component={AccountsPayable} />
                    <Route path={Routes.CommissionsReport} exact component={CommissionsReportPage} />
                    <Route path={Routes.Payees} exact component={Payees} />
                    <Route path={Routes.ProfitLoss} exact component={ProfitLoss} />
                    <Route path={Routes.VendorClients} exact component={VendorClientsPage} />

                    <Route path={Routes.DevFormTest} exact component={FormTestPage} />
                    <Route path={Routes.DevLanding} exact component={DevLandingPage} />
                    <Route path={Routes.DevButtonsDemo} exact component={DevButtonsDemoPage} />

                    <Route path="*" component={NotFound} />
                  </Switch>
                </Layout>
              </PageInitializer>
            );
          }}
        />
      </Switch>
    </>
  );
};
