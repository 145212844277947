import {
  AttachmentEntityType,
  CapabilityType,
  FileVisibility,
  NoteEntityType,
  NoteSource,
  Vendor
} from '../../shared/api/clients';
import {
  useContractorCanManageVendor,
  useCurrentVendor,
  useHasCapability,
  useIsAdmin,
  useIsGeneralContractor
} from '../../shared/store';
import { Box, Stack } from '@mui/material';
import { ContactInfoWidget } from '../../components/Profiles/ContactInfoWidget';
import { AddressWidget } from '../../components/Profiles/AddressWidget';
import { NotesWidget } from '../../components/Profiles/NotesWidget';
import React from 'react';
import { UpdateVendorAddressModal } from './UpdateVendorAddressModal';
import { UpdateVendorContactModal } from './UpdateVendorContactModal';
import { RooAvatar } from '../../components';
import { RooRating } from '../../components/RooRating';
import { FileList } from '../../components/Files/FileList';
import { ServicesWidget } from './ServicesWidget';
import { PreferencesWidget } from './PreferencesWidget';
import { useDisclosure } from '@roo/lib';

export const SectionBasics = ({ vendor }: { vendor: Vendor }) => {
  const gcCanManage = useContractorCanManageVendor(vendor.id);
  const isAdmin = useIsAdmin();
  const isVendorMember = useCurrentVendor()?.id === vendor.id;
  const isManager = useHasCapability(CapabilityType.PropertyManager);

  const addressDisclosure = useDisclosure(false);
  const contactDisclosure = useDisclosure(false);

  const canEdit = gcCanManage || isAdmin || isVendorMember;

  return (
    <Stack alignItems={'center'} justifyContent={'center'} p={3} py={7}>
      <Stack sx={{ maxWidth: '1000px', width: '100%' }} spacing={4}>
        <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
          <RooAvatar name={vendor.companyName} size={'xl'} avatarUrl={''} />
          <Box sx={{ ml: 3 }}>
            <h4 style={{ margin: 0 }}>{vendor.companyName}</h4>
            {(isManager || gcCanManage) && (
              <Box sx={{ pt: 1 }}>
                <RooRating value={vendor.score} size={'medium'} readOnly />
              </Box>
            )}
          </Box>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'}>
          <ContactInfoWidget
            canEdit={canEdit}
            name={vendor.companyName}
            nameLabel={'Vendor Name'}
            contactInfo={vendor.contactInfo}
            onEdit={() => contactDisclosure.open()}
          />
          <AddressWidget canEdit={canEdit} address={vendor.address} onEdit={() => addressDisclosure.open()} />
        </Stack>
        <Stack direction={'row'} flexGrow={1} width={'100%'}>
          <ServicesWidget canEdit={canEdit} vendor={vendor} />
          {(gcCanManage || isAdmin) && <PreferencesWidget vendor={vendor} canEdit={canEdit} />}
        </Stack>

        {gcCanManage && (
          <NotesWidget noteSource={NoteSource.VendorProfile} entityType={NoteEntityType.Vendor} entityId={vendor.id} />
        )}
        <FileList
          grow={true}
          entityType={AttachmentEntityType.Vendor}
          entityId={vendor.id}
          visibility={FileVisibility.Public}
          allowUpload={false}
          forceAllowDelete={false}
          requireDocType={true}
        />
        <UpdateVendorAddressModal disclosure={addressDisclosure} vendor={vendor} />
        <UpdateVendorContactModal disclosure={contactDisclosure} vendor={vendor} />
      </Stack>
    </Stack>
  );
};
